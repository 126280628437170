import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, makeStyles, Grid, Typography, Box, Hidden } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },

  rightTextContainer: {
    marginLeft: 'auto',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
    },
  },
  leftTextContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  spacer: {
    padding: theme.spacing('100px', 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
      margin: 'auto',
    },
  },
  order1: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  order2: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
}));

export default function SectionC() {
  const classes = useStyles();

  const { image1, image2, image3 } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "home-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
          )
        }
      }
      image2: file(relativePath: { eq: "home-2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image3: file(relativePath: { eq: "home-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
          )
        }
      }
    }
  `);

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={8}>
            <Box maxWidth="675px" className={classes.leftTextContainer}>
              <Typography variant="h1" paragraph>
                Travel more.
              </Typography>
              <Typography variant="h2" paragraph>
                FamGuru Fam Trip App is designed to make your life easier as a Travel Advisor. Fam Trips are essential
                to increasing your knowledge so you can sell to clients. But when you’re traveling and trying to keep up
                with client work, who has time to organize, share and market what you’ve learned?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <GatsbyImage image={getImage(image1)} alt="" />
          </Grid>
        </Grid>
        <div className={classes.spacer} />
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={6} className={classes.order1}>
            <GatsbyImage image={getImage(image2)} alt="" />
          </Grid>
          <Grid item xs={12} md={6} className={classes.order2}>
            <Box maxWidth="575px" className={classes.rightTextContainer}>
              <Typography variant="h1" paragraph>
                Convert clients.
              </Typography>
              <Typography variant="h2" paragraph>
                Without a good way to organize and sell what you’ve learned, your business won’t grow. As travel
                advisors ourselves, we believe Fam Trips and other travel that expands our knowledge should support the
                growth of our businesses, not detract from it.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <div className={classes.spacer} />
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={8}>
            <Box maxWidth="700px" className={classes.leftTextContainer}>
              <Typography variant="h1" paragraph>
                Make your travel business easier.
              </Typography>
              <Typography variant="h2" paragraph>
                With FamGuru, site inspections, activities, photos and notes are organized and accessible now and in the
                future. Customize trip reports for clients or your agency. Share your learnings on social media or with
                industry colleagues.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <GatsbyImage height='100px' width='100px' image={getImage(image3)} alt="" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
