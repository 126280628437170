/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import Icon from '../assets/icons/J-icon.inline.svg';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 350,
    // minHeight: 448,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 12,
    margin: theme.spacing(0, 1),
  },
  textContainer: {
    minHeight: 175,
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  imageWrapper: {
    paddingBottom: 18,
    display: 'flex',
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 18,
    '& span': {
      marginLeft: 16,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export const CardText = ({ children }) => children;
export const CardImage = ({ children }) => children;

export default function Card({ children, footerText }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>{children.find(({ type }) => type === CardImage)}</div>
      <div className={classes.textContainer}>
        <Typography>{children.find(({ type }) => type === CardText)}</Typography>
        <div className={classes.caption}>
          <Icon />
          <span>
            <Typography>{footerText}</Typography>
          </span>
        </div>
      </div>
    </div>
  );
}
