import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { makeStyles, Typography, Container, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing('100px', 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },

  imageContainer: {
    padding: theme.spacing(12, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },

  image: {
    height: 450,
    borderRadius: 10,
    overflow: 'hidden',
  },

  avatar: {
    width: 128,
    height: 128,
    backgroundColor: '#e88154',
    opacity: 0.2,
    borderRadius: '50%',
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },

  author: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
}));

export default function SectionE() {
  const classes = useStyles();

  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home-4.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="subtitle1" align="center" paragraph>
          OUR STORY
        </Typography>
        <Typography variant="h1" align="center">
          The moment we realized there had to be a better way…
        </Typography>
        <div className={classes.imageContainer}>
          <BackgroundImage Tag="div" {...bgImage} preserveStackingContext className={classes.image} />
        </div>
        <Typography variant="h2" align="center" paragraph>
          My client asked me about the beach at a resort in Mexico and how it compared to the beach of another resort
          nearby. I’d been on a fam and seen both hotels two years prior, but I could not remember even seeing the beach
          at the first property. I didn’t have a photo and couldn’t find a good review online. I fudged it “they’re both
          nice.” He booked the first resort.
        </Typography>
        <Box paddingBottom={4} />
        <Typography variant="h2" align="center">
          Fast forward two months, he and his family are at the resort. The dreaded phone call comes: “The beach is
          awful – too rocky – the kids can’t swim. Get us out of here.” He hasn’t booked with me since. If only I’d had
          notes, observations, comparisons, something to remind me of what I’d seen!
        </Typography>
        <Box paddingBottom={4} />
        <Typography variant="h2" align="center" paragraph>
          Patchy memory and imperfect information can get us Travel Advisors into trouble. It can also hold us back from
          using what we learn to market and sell to clients. And when we can’t market and sell as we should, we miss out
          on future revenue and can’t grow our businesses and enhance the lifestyle we’ve chosen as Travel Advisors.
        </Typography>
        <Box paddingBottom={4} />
        <Typography variant="h2" align="center">
          This is why we created FamGuru – to eliminate Fam disorganization, provide structure to what we learn and use
          this insight for our own and our clients’ benefit. Our team are all Travel Advisors ourselves, so we
          understand the need to create this structure and save time in our days so we can do what we do best: sell
          travel. FamGuru allows Travel Advisors to do just that: travel, share our insights and grow our businesses
          through simplification, organization and tools to market to clients.
        </Typography>
        {/* <div className={classes.author}>
          <div className={classes.avatar} />
          <div>
            <Typography variant="h1" paragraph>
              Carrie Wallace
            </Typography>
            <Typography variant="h2" className={classes.subtitle}>
              TRAVEL ADVISOR & FOUNDER, FAMGURU
            </Typography>
          </div>
        </div> */}
      </Container>
    </section>
  );
}
