import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ButtonBase, Container, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import Card, { CardText, CardImage } from '../../components/card';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
  flexContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  spacer: {
    padding: theme.spacing(7, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  iconButton: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  buttonsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

const slideStyle = {
  minHeight: 490,
  maxWidth: 350,
  paddingBottom: 'unset',
  margin: 'auto',
  //   marginBottom: 50,
};

export default function SectionD() {
  const classes = useStyles();

  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { image1, image2, image3 } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "testimonial1.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image2: file(relativePath: { eq: "testimonial2.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image3: file(relativePath: { eq: "testimonial3.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

const testimonials = [
  {
    name: 'Kevin Doheny, Cerulean World Travel',
    testimonial:
      '“With FamGuru I have an archive of all my travels which I can refer to for years to come. No more relying on my memory or paging through thousands of photos on my phone!”',
      image: image1
  },
  {
    name: 'Julie Hamilton, Family on the Go Travel',
    testimonial:
      '“I love that FamGuru is a one stop shop that helps me to store my Fam trip experiences and share my knowledge with my clients”.',
      image: image2
  },
  {
    name: 'Jay Johnson, Coastline Travel',
    testimonial:
      '“I finally have a way to organize everything I learn when I travel and don’t have to spend hours writing up my notes.”',
      image: image3
  },
];

  const TestimonialCard = ({testimonial, index}) => (
    <Slide index={index} style={matches && slideStyle}>
      <Card footerText={testimonial.name}>
        <CardImage>
          <GatsbyImage image={getImage(testimonial.image)} alt="image" />
        </CardImage>
        <CardText>
          {testimonial.testimonial}
        </CardText>
      </Card>
    </Slide>
  );

  return (
    <section className={classes.root}>
      <Container>
        <CarouselProvider
          naturalSlideWidth={1}
          naturalSlideHeight={1}
          totalSlides={4}
          visibleSlides={matches ? 1 : 3}
          //   orientation={matches ? 'vertical' : 'horizontal'}
          isIntrinsicHeight={!matches}
        >
          <Typography variant="h1" paragraph>
            FamGuru: The Fam Trip App for Travel Advisors
          </Typography>
          <div className={classes.flexContainer}>
            <div>
              <Typography variant="h2" paragraph={matches}>
                You love traveling, want more and better clients, and have no time. We get that.
              </Typography>
              <Typography variant="h2">FamGuru helps make Travel Advising easier and more lucrative.</Typography>
            </div>
          </div>
          <div className={classes.spacer} />
          <div>
            <Slider>
              {testimonials.map((testimonial, index) => (<TestimonialCard key={`textimonial_${index}`} testimonial={testimonial} index={index}/>))}
            </Slider>
          </div>
        </CarouselProvider>
      </Container>
    </section>
  );
}
