import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';
import SectionA from '../features/home/sectionA';
import SectionB from '../features/home/sectionB';
import SectionC from '../features/home/sectionC';
import SectionD from '../features/home/sectionD';
import SectionE from '../features/home/sectionE';
import BookACall from '../features/contact/sectionB'

export default function HomePage() {

  const { seoImage } = useStaticQuery(graphql`
    query {
      seoImage: file(relativePath: { eq: "featured-image.png" }) {
        childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
      }
    }
  `);
  return (
    <>
      <SEO 
        title="Travel, Share & Grow your business with FamGuru" 
        description="You love traveling, want more and better clients, and have no time. We get that. FamGuru helps make Travel Advising easier and more lucrative."
        image={seoImage.childImageSharp.resize}
        />
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <BookACall />
    </>
  );
}
