import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { Container, makeStyles, Typography, Box } from '@material-ui/core';
import Header from '../header';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.416) 50.2%, rgba(0, 0, 0, 0) 100%)',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-10),
    },
  },
  textContainer: {
    maxWidth: 480,

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

export default function SectionA() {
  const classes = useStyles();

  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home-bg.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage Tag="section" {...bgImage} preserveStackingContext className={classes.root}>
      <Header alt/>
      <Container className={classes.main}>
        <div className={classes.textContainer}>
          <Typography variant="h1" color="textSecondary" paragraph>
            Organize your FamTrip knowledge so you can sell more travel
          </Typography>
          <Box paddingY={1} />
          <Typography variant="h2" color="textSecondary" paragraph>
            Travel, Share & Grow your business with FamGuru
          </Typography>
        </div>
      </Container>
    </BackgroundImage>
  );
}
