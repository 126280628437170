import React from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  useMediaQuery,
  Hidden,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },

  textContainer: {
    maxWidth: 720,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },

  stepperIconRoot: ({ color }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 100,
    '& > *:nth-child(1)': {
      height: 56,
      width: 56,
      opacity: 0.2,
      backgroundColor: color,
      borderRadius: '50%',
    },
    '& > *:nth-child(2)': {
      height: 12,
      width: 12,
      backgroundColor: color,
      borderRadius: '50%',
      position: 'absolute',
    },
  }),

  stepperWrapper: {
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DashedConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 36,
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 4,
    },
  },
  lineHorizontal: {
    borderColor: '#000',
    borderTopStyle: 'dashed',
    borderTopWidth: 2,
  },
  lineVertical: {
    borderColor: '#000',
    borderLeftStyle: 'dashed',
    borderLeftWidth: 2,
    height: 57,
  },
}))(StepConnector);

const StepperIcon = ({ color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.stepperIconRoot}>
      <span />
      <span />
    </div>
  );
};

export default function SectionB() {
  const classes = useStyles();

  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <section className={classes.root}>
      <Container>
        <div className={classes.textContainer}>
          <Typography variant="h1" align="center" paragraph>
            FamGuru: The Fam Trip App for Travel Advisors
          </Typography>
          <Typography variant="h2" align="center" paragraph={matches}>
            You love traveling, want more and better clients, and have no time. We get that.
          </Typography>
          <Typography variant="h2" align="center">
            FamGuru helps make Travel Advising easier and more lucrative.
          </Typography>
        </div>
        <div className={classes.stepperWrapper}>
          <Stepper orientation={matches ? 'vertical' : 'horizontal'} alternativeLabel connector={<DashedConnector />}>
            <Step>
              <StepLabel StepIconComponent={() => <StepperIcon color="#DC458B" />}>
                <Typography variant="h3">Organize Fam Trip learnings</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <StepperIcon color="#41B973" />}>
                <Typography variant="h3">Automate Fam travel reports</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <StepperIcon color="#10182A" />}>
                <Typography variant="h3">Share insights with clients real time</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <StepperIcon color="#E88154" />}>
                <Typography variant="h3">Sell more effectively</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <StepperIcon color="#10182A" />}>
                <Typography variant="h3">Travel more, stress less</Typography>
              </StepLabel>
            </Step>
          </Stepper>
        </div>
      </Container>
    </section>
  );
}
