import React from 'react';
import { makeStyles, Typography, Container, Button } from '@material-ui/core';

import Image from '../../assets/images/bg.inline.svg';
import Image2 from '../../assets/images/bg-2.inline.svg';
import { navigate } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },

  card: {
    backgroundColor: '#f4c7dc',
    padding: theme.spacing(3, 6, 5, 6),
    position: 'relative',
    borderRadius: 23,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },

  imageWrapper: {
    paddingBottom: 80,
    display: 'flex',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  textContainer: {
    maxWidth: 720,
  },

  buttonWrapper: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },
  button: {
    minWidth: 220,
    marginLeft: 'auto',
    backgroundColor: '#fff',
  },

  image1: {
    position: 'relative',
    left: -24,
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
  image2: {
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function SectionB() {
  const classes = useStyles();

  const handleCallClick = (e) => {
    navigate('https://calendly.com/carrie-famguru/15min');
  }
  return (
    <section className={classes.root}>
      <Container>
        <div className={classes.card}>
          <div className={classes.imageWrapper}>
            <Image className={classes.image1} />
            <Image2 className={classes.image2} />
          </div>

          <div className={classes.container}>
            <div className={classes.textContainer}>
              <Typography variant="h1" paragraph>
                Book a call with our experts
              </Typography>
              <Typography variant="h2" color="primary" paragraph>
                You love traveling, want more and better clients, and have no time. We get that.
              </Typography>
              <Typography variant="h2" color="primary">
                FamGuru helps make Travel Advising easier and more lucrative
              </Typography>
            </div>
            <div className={classes.buttonWrapper}>
              <Button variant="contained" size="large" className={classes.button} onClick={handleCallClick}>
                Book a Call
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
